import type {
  ComponentRef,
  Experiments,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';

import { Experiment } from '../../constants/experiments';
import { ProfileLayout } from '../../types';
import {
  PROFILE_WIDGET_HORIZONTAL_LAYOUT_STYLE_PARAM,
  PROFILE_WIDGET_SIDEBAR_LAYOUT_STYLE_PARAM,
} from '../constants/apps';
import { getCardCompRef, getLayoutType } from './styles';
import { EditorSDK } from '../constants/types';

const createBooleanStyleParam = (key: string, value: boolean) => ({
  type: 'boolean' as 'boolean',
  key,
  param: {
    value,
  },
});

export const refreshApp = (
  editorSDK: FlowEditorSDK,
  experiments: Experiments,
  source: string,
) => {
  return experiments.enabled(Experiment.EnableOOIInEditor)
    ? editorSDK.document.application.livePreview.refresh('', {
        source,
        shouldFetchData: true,
      })
    : editorSDK.tpa.app.refreshApp('');
};

export const setBooleanStyleParamForWidgets = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  key: string,
  value: boolean,
) => {
  const { applicationId } = await editorSDK.tpa.app.getDataByAppDefId(
    '',
    appDefId,
  );
  const appComponents = await editorSDK.tpa.app.getAllCompsByApplicationId(
    '',
    applicationId,
  );
  if (!appComponents) {
    return;
  }

  const setBooleanParamForComp = async ({ id }: { id: string }) => {
    const compRef = await editorSDK.components.getById('', { id });
    const styleParams = [createBooleanStyleParam(key, value)];
    await editorSDK.document.tpa.setStyleParams('', { compRef, styleParams });
  };

  return Promise.all(appComponents.map(setBooleanParamForComp));
};

const setProfileCardLayout = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  layout: ProfileLayout,
  widgetRef?: ComponentRef,
): Promise<void> => {
  const compRef = widgetRef || (await getCardCompRef(editorSDK, appDefId));
  if (!compRef) {
    return;
  }

  return editorSDK.document.tpa.setStyleParams('', {
    compRef,
    styleParams: [
      layout === ProfileLayout.Card
        ? PROFILE_WIDGET_SIDEBAR_LAYOUT_STYLE_PARAM
        : PROFILE_WIDGET_HORIZONTAL_LAYOUT_STYLE_PARAM,
    ],
  });
};

export const isHorizontalLayout = async (
  editorSDK: EditorSDK,
  appDefId: string,
) => {
  const layout = await getLayoutType(editorSDK, appDefId);

  return layout === ProfileLayout.FullWidth;
};

const isVerticalLayout = async (editorSDK: EditorSDK, appDefId: string) => {
  const layout = await getLayoutType(editorSDK, appDefId);

  return !layout || layout === ProfileLayout.Card;
};

export const setHorizontalLayout = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  widgetRef?: ComponentRef,
) => {
  if (await isVerticalLayout(editorSDK, appDefId)) {
    return setProfileCardLayout(
      editorSDK,
      appDefId,
      ProfileLayout.FullWidth,
      widgetRef,
    );
  }
};

export const setSidebarLayout = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
) => {
  if (await isHorizontalLayout(editorSDK, appDefId)) {
    return setProfileCardLayout(editorSDK, appDefId, ProfileLayout.Card);
  }
};
