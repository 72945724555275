import { SANTA_MEMBERS_APP_DEF_ID } from '../constants/apps';
import { isWidgetInSOSP, setProfileCardWidth } from './components';
import { PW_HORIZONTAL_WIDTH, PW_VERTICAL_WIDTH } from '../constants/members';
import { EditorSDK, ProfileCardAppApi } from '../constants/types';
import { myAccountAppDefinitionId } from '../../constants/app-definition-id';
import { MyAccountApi } from '../../types';

interface SantaMembersAppApi {
  setSidebarLayout(): Promise<void>;
  setHorizontalLayout(pwHeight: number): Promise<void>;
  refreshMembersAreaApps(): Promise<void>;
  setProfileWidgetHeight(newHeight: number): Promise<void>;
}

export const getSantaMembersApi = (editorSDK: EditorSDK) => {
  const payload = { appDefinitionId: SANTA_MEMBERS_APP_DEF_ID };
  const santaMembersApi = editorSDK.application.getPublicAPI('', payload);

  return santaMembersApi as Promise<SantaMembersAppApi>;
};

export const createAppAPI = (editorSDK: EditorSDK): ProfileCardAppApi => ({
  setSidebarLayout: async (compId: string) => {
    const isCompInSOSP = await isWidgetInSOSP(editorSDK, compId);
    await setProfileCardWidth(editorSDK, compId, PW_VERTICAL_WIDTH);
    if (isCompInSOSP) {
      const santaMembersApi = await getSantaMembersApi(editorSDK);
      await santaMembersApi.setSidebarLayout();
    }
  },
  setHorizontalLayout: async (compId: string, pwHeight: number) => {
    const isCompInSOSP = await isWidgetInSOSP(editorSDK, compId);
    await setProfileCardWidth(editorSDK, compId, PW_HORIZONTAL_WIDTH);
    if (isCompInSOSP) {
      const santaMembersApi = await getSantaMembersApi(editorSDK);
      await santaMembersApi.setHorizontalLayout(pwHeight);
    }
  },
  refreshMembersAreaApps: async () => {
    const membersEditorApi = await getSantaMembersApi(editorSDK);
    return membersEditorApi.refreshMembersAreaApps();
  },
  setProfileWidgetHeight: async (newHeight: number) => {
    const membersEditorApi = await getSantaMembersApi(editorSDK);
    return membersEditorApi.setProfileWidgetHeight(newHeight);
  },
  setAvatarVisibility: async (isVisible: boolean) => {
    const appAPI = await editorSDK.application.getPublicAPI('', {
      appDefinitionId: myAccountAppDefinitionId,
    });
    return (appAPI as MyAccountApi).setAvatarVisibility(isVisible);
  },
  getIsProfileCardInSOSP: async (compId) => isWidgetInSOSP(editorSDK, compId),
});
