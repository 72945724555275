import type { AppEditorApi } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { MEMBERS_AREA_V2 } from '@wix/app-definition-ids';

import { isMembersAreaV1Context } from './members-area-api';
import { hideProfileCardContainer } from './profile-page-bob-api';
import { globalAppState } from './global-app-state';
import { Experiment } from '../../constants/experiments';

type ComponentData = {
  appDefinitionId?: string;
};

const isProfileCardInMembersAreaSlot = async (
  editorSDK: FlowEditorSDK,
  parentId: string,
) => {
  const componentRef = await editorSDK.components.getById('', {
    id: parentId,
  });

  if (componentRef) {
    const parentComponentData = (await editorSDK.components.data.get('', {
      componentRef,
    })) as ComponentData;

    return parentComponentData?.appDefinitionId === MEMBERS_AREA_V2;
  }

  return false;
};

export const getEditorAPI = (
  editorSDK: FlowEditorSDK,
): Partial<AppEditorApi> => {
  return {
    beforeComponentRemoved: async (data) => {
      if (await isMembersAreaV1Context(editorSDK)) {
        return;
      }

      const flowAPI = globalAppState.getFlowAPI();
      const parentComponentId = data.componentDefinition.parent;

      const shouldHideProfileCardContainer =
        flowAPI?.experiments.enabled(
          Experiment.MakeProfileCardRemovableInNewMA,
        ) &&
        parentComponentId &&
        (await isProfileCardInMembersAreaSlot(editorSDK, parentComponentId));

      if (shouldHideProfileCardContainer) {
        return hideProfileCardContainer(editorSDK);
      }
    },
  };
};
